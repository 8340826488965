
import { defineComponent, computed } from "vue";
import { OMISTUSMUODOT } from "@/constants/omistusmuoto";

export default defineComponent({
    props: {
        modelValue: Object,
        saveDisabled: Boolean,
        newOrganisation: Boolean,
    },
    emits: ["update:modelValue", "addOrganisation"],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        return { form, OMISTUSMUODOT };
    },
});
