
import { defineComponent, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import OrganisationForm from "@/components/OrganisationForm.vue";
import OrganisationsList from "../components/OrganisationsList.vue";
import { OrganisationForm as OrganisationFormType } from "@/@types/OrganisationForm";
import { Organisation } from "@/@types/Organisation";
import { OMISTUSMUODOT } from "@/constants/omistusmuoto";

export default defineComponent({
    components: { OrganisationForm, OrganisationsList },
    setup() {
        const initialForm: OrganisationFormType = {
            name: "",
            omistusmuoto: null,
            palveluntuottaja: false,
            business_id: "",
        };
        const form: OrganisationFormType = reactive({ ...initialForm });
        const store = useStore();

        const saveDisabled = computed(() => !form.name);

        const addOrganisation = () => {
            const mappedForm = {
                ...form,
                omistusmuoto: form.omistusmuoto?.id,
                valvoja: !form.palveluntuottaja,
            };
            store.dispatch("saveOrganisation", mappedForm);
            Object.assign(form, initialForm);
        };
        const fetchOrganisations = () => store.dispatch("fetchOrganisations");
        onMounted(fetchOrganisations);

        const organisations = computed(() =>
            [...store.state.organisations]
                .sort((o1: Organisation, o2: Organisation) => (o1.id < o2.id ? 1 : -1))
                .map((org) => ({
                    ...org,
                    omistusmuoto: OMISTUSMUODOT.find((om) => om.id === org.omistusmuoto)?.name,
                }))
        );

        return { form, addOrganisation, organisations, saveDisabled };
    },
});
