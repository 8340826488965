import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-3 q-pl-md" }
const _hoisted_5 = { class: "col-3 q-pl-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.newOrganisation)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_1, "Lisää organisaatio"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_input, {
          outlined: "",
          modelValue: _ctx.form.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.name = $event)),
          dense: "",
          label: "Nimi",
          "no-error-icon": "",
          "hide-bottom-space": ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_q_input, {
          outlined: "",
          modelValue: _ctx.form.business_id,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.business_id = $event)),
          dense: "",
          label: "Y-tunnus",
          "no-error-icon": "",
          "hide-bottom-space": ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_q_select, {
          modelValue: _ctx.form.omistusmuoto,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.omistusmuoto = $event)),
          outlined: "",
          options: _ctx.OMISTUSMUODOT,
          dense: "",
          label: "Omistusmuoto",
          "dropdown-icon": "fas fa-chevron-down",
          "options-dense": "",
          "option-value": "id",
          "option-label": "name"
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          class: "q-ml-md",
          color: "primary",
          icon: _ctx.newOrganisation ? 'fas fa-plus' : 'fas fa-save',
          "no-caps": "",
          label: "Tallenna",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('addOrganisation', undefined))),
          disabled: _ctx.saveDisabled
        }, null, 8, ["icon", "disabled"])
      ]),
      (_ctx.newOrganisation)
        ? (_openBlock(), _createBlock(_component_q_checkbox, {
            key: 0,
            label: "Organisaatio on palveluntuottaja",
            modelValue: _ctx.form.palveluntuottaja,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.palveluntuottaja = $event)),
            val: _ctx.form.palveluntuottaja
          }, null, 8, ["modelValue", "val"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}