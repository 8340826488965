export const OMISTUSMUODOT = [
    {
        id: "yksityinen",
        name: "Yksityinen",
    },
    {
        id: "valtio",
        name: "Valtio",
    },
    {
        id: "yleishyodyllinen",
        name: "Yleishyödyllinen",
    },
];
